function changeColorForSVG(targetColor) {
    const currentColor = targetColor === '#8E74E6' ? '#fff' : '#8E74E6';
    const newColor = targetColor;

    const svgElements = document.querySelectorAll(`#nav-logo path[fill="${currentColor}"], #nav-logo rect[fill="${currentColor}"]`);

    svgElements.forEach(element => {
        element.style.transition = 'fill 0.5s';
        element.setAttribute('fill', newColor);
    });
}

function checkNavPositionAndAdjustColor() {
    const header = document.getElementById('header');
    const nav = document.getElementById('nav-logo');

    const headerBottom = header.getBoundingClientRect().bottom + window.pageYOffset;
    const navTop = nav.getBoundingClientRect().top + window.pageYOffset;

    if (navTop > headerBottom) {
        changeColorForSVG('#8E74E6');
    } else {
        changeColorForSVG('#fff');
    }
}

window.addEventListener('scroll', checkNavPositionAndAdjustColor);
window.addEventListener('resize', checkNavPositionAndAdjustColor);

checkNavPositionAndAdjustColor();
