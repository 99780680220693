window.addEventListener("load", function () {
  function Nav() {
    const nav = document.querySelector(".nav-popup");
    const navToggle = document.querySelector(".nav-toggle");
    const navButtons = document.querySelectorAll(".nav-btn");
    const overlay = document.querySelector(".fullscreenOverlay");

    if (overlay.classList.contains("hidden")) {
      overlay.classList.remove("hidden");
    } else {
      overlay.classList.add("hidden");
    }

    navButtons.forEach((button, index) => {
      button.addEventListener("click", function () {
        document.body.classList.toggle("overflow-hidden");
        nav.classList.toggle("hidden");
        nav.classList.toggle("opacity-0");
        navToggle.classList.toggle("rotate-[135deg]");
      });
    });

    // Mobile navigation logic
    const mobbtn = document.querySelector(".menu-btn");
    const meganav = document.querySelector(".mob-nav");
    const closeBtn = document.querySelector(".close-btn");

    if (mobbtn) {
      mobbtn.addEventListener("click", function () {
        meganav.classList.toggle("open");
      });
    }

    if (closeBtn) {
      closeBtn.addEventListener("click", function () {
        meganav.classList.remove("open");
      });
    }

    if (meganav) {
      meganav.querySelectorAll("a").forEach((a) => {
        a.addEventListener("click", () => {
          if (a.href.indexOf("#") !== -1) {
            meganav.classList.toggle("open");
          }
        });
      });
    }
  }

  Nav(); // Initialize the function
});
