import Flickity from "flickity-fade";

function Slider(el) {
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  let flk;

  function initializeSlider() {
    flk = new Flickity(el, options);

    function nextSlide() {
      flk.next();
    }

    function prevSlide() {
      flk.previous();
    }

    function setActiveBtn(btns, index) {
      btns.forEach((btn) => btn.classList.remove("active"));
      btns[index].classList.add("active");
    }

    if (options.customNav) {
      const btns = [
        ...document.querySelectorAll(options.customNav + " .slider-button"),
      ];

      btns.forEach((btn) => {
        btn.addEventListener("click", (e) => {
          const index = btns.indexOf(btn);
          flk.select(index);
        });
      });

      setActiveBtn(btns, 0);

      flk.on("change", (index) => {
        setActiveBtn(btns, index);
      });
    }

    document
      .querySelectorAll(options?.nextButton)
      .forEach((el) => el.addEventListener("click", nextSlide));

    document
      .querySelectorAll(options?.prevButton)
      .forEach((el) => el.addEventListener("click", prevSlide));

    document.addEventListener("lazyloaded", (e) => {
      flk.resize();
    });
  }

  function destroySlider() {
    if (flk) {
      flk.destroy();
      flk = undefined;
    }
  }

  function checkAndToggleSlider() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1023) {
      if (!flk) initializeSlider();
    } else {
      destroySlider(); // Destroy if screen width is 1080px or more
    }
  }
  const nextSlideButton = document.getElementById("nextSlide");
  if (nextSlideButton) {
    nextSlideButton.addEventListener("click", (e) => {
      e.preventDefault(); // Prevent default action if it's inside an <a> tag
      nextSlide();
    });

    document.querySelectorAll(options?.nextButton).forEach((btn) => {
      btn.addEventListener("click", nextSlide);
    });

    document.querySelectorAll(options?.prevButton).forEach((btn) => {
      btn.addEventListener("click", prevSlide);
    });

    document.addEventListener("lazyloaded", () => {
      flk.resize();
    });
  }
  // Initial check
  checkAndToggleSlider();

  // Add event listener for window resize
  window.addEventListener("resize", checkAndToggleSlider);

  // Return a cleanup function to remove event listener when the component unmounts or is no longer needed
  return () => {
    window.removeEventListener("resize", checkAndToggleSlider);
    destroySlider();
    console.log("Slider cleanup complete.");
  };
}

export default Slider;
