window.addEventListener("load", function () {
  var formFields = document.querySelectorAll(
    ".trial-parent input, .textarea-parent textarea, .trial-parent select"
  );

  formFields.forEach(function (field) {
    field.addEventListener("focus", handleFocusBlurInput);
    field.addEventListener("blur", handleFocusBlurInput);
    field.addEventListener("input", handleFocusBlurInput);
  });

  function handleFocusBlurInput(event) {
    var input = event.target;
    var label = input.previousElementSibling;

    if (label && label.tagName.toLowerCase() === "label") {
      if (input.value !== "" || document.activeElement === input) {
        label.classList.add("shrink");
      } else {
        label.classList.remove("shrink");
      }
    }
  }
});
