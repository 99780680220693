function toggleVisibilityBasedOnWidth() {
  const screenWidth = window.innerWidth;
  document.querySelectorAll('[id^="toggleContent-"]').forEach((content) => {
    if (screenWidth <= 1023) {
      // If the screen width is 1023px or less, remove 'hidden' from all content divs
      content.classList.remove("hidden");
    } else {
      // Otherwise, add 'hidden' to ensure they are hidden on larger screens
      content.classList.add("hidden");
    }
  });
}

// This function initializes the event listeners for the toggle buttons
function initializeToggleButtons() {
  document.querySelectorAll('[id^="toggleButton-"]').forEach((button) => {
    button.addEventListener("click", function () {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1023) {
        // Only toggle content visibility if screen width is 1023px or less
        var entryId = this.id.split("-")[1];
        var contentDiv = document.getElementById("toggleContent-" + entryId);
        if (contentDiv) {
          contentDiv.classList.toggle("hidden");
          this.classList.toggle("no-border"); // Optionally toggle a class on the button itself
        }
      }
    });
  });
}

// Run these functions when the window loads
window.addEventListener("load", function () {
  toggleVisibilityBasedOnWidth(); // Ensure correct initial visibility
  initializeToggleButtons(); // Setup button click handlers
});

// Also adjust content visibility when the window is resized
window.addEventListener("resize", toggleVisibilityBasedOnWidth);

window.addEventListener("load", function () {
  document.querySelectorAll('[id^="toggleButton-"]').forEach((button) => {
    button.addEventListener("click", function () {
      var entryId = this.id.split("-")[1];
      var contentDiv = document.getElementById("toggleContent-" + entryId);

      if (contentDiv) {
        contentDiv.classList.toggle("hidden");
        this.classList.toggle("no-border"); // Toggle the border class on the button
      }
    });
  });
});
