window.addEventListener("load", function () {
  function initializeMenu(menuSelector, toggleSelector) {
    const menu = document.querySelector(".submenu");
    if (!menu) return;

    // Add event listener to toggle the submenu
    const toggleElement = document.querySelector(toggleSelector);
    if (toggleElement) {
      toggleElement.addEventListener("click", function () {
        menu.classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      });
    }

    document.addEventListener("click", (event) => {
      const isMenuItem = event.target.closest(menuSelector);
      const isToggleElement = event.target.closest(toggleSelector);

      if (isMenuItem && !isToggleElement) {
        // Open the submenu if a menu item is clicked and it's not the toggle element
        menu.classList.remove("hidden");
        document.body.classList.add("overflow-hidden");
        menu.classList.add("fade-in-nav");
      } else if (!isMenuItem && !event.target.closest(".submenu")) {
        // Hide the submenu if clicking outside of the submenu and not on a menu item
        menu.classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      }
    });
  }

  initializeMenu(".has-submenu", ".toggle-submenu");
});
